export default {
  grayLow: "#434343",
  grayHigh: "#666666",
  pinkLow: "#741b47",
  pinkHigh: "#ff00ff",
  purpleLow: "#351c75",
  purpleHigh: "#9900ff",
  greenLow: "#0a700a",
  greenHigh: "#16cf16",
  blueLow: "#1c4587",
  blueHigh: "#1155cc",
};
